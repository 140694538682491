<template>
  <div>
    <page-content>
      <SearchPanel
        flex
        size="mini"
      >
        <el-button
          type="primary"
          style="margin-bottom: 20px"
          size="mini"
          @click="openInfo(undefined, 'add')"
        >
          新增
        </el-button>
      </SearchPanel>
      <el-table
        v-loading="state.loading"
        class="table-container"
        :data="state.tableData"
        border
        style="width: 100%"
        highlight-current-row
        :header-cell-style="{
          background: '#D9DDE1',
          color: '#0D0202',
          'font-weight': 400,
          fontSize: '12px',
        }"
        :cell-style="{
          padding: '5px 0',
        }"
        row-key="id"
      >
        <el-table-column
          prop="id"
          label="编号"
          width="150"
        />
        <el-table-column
          prop="roomName"
          label="房源名称"
          width="250"
        />
        <el-table-column
          prop="host"
          label="房主"
          width="250"
        />
        <el-table-column
          prop="mobile"
          label="电话"
          width="250"
        />
        <el-table-column
          label="操作"
        >
          <template #default="{ row }">
            <el-button type="text" @click="openInfo(row)">查看</el-button>
            <el-button type="text" @click="openInfo(row, 'edit')">编辑</el-button>
            <el-button type="text" @click="deleteItem(row, 0)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="state.tableData && state.tableData.length > 0"
        :current-page="state.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="state.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="state.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </page-content>
  </div>
</template>

<script setup>
  import { reactive, onMounted } from 'vue'
  import api from '@/http/api'
  import router from '@/router'
  import axios from '@/http/axios'
  import { MessageBox, Message  } from 'element-ui'

  const state = reactive({
    currentPage: 1,
    pageSize: 10,
    total: 0,
    tableData: [],
    loading: false
  })

  function deleteItem (row) {
    MessageBox.confirm('确定删除？', '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      axios.get(api.lease.del, {
        params: {
          id: row.id
        }
      }).then((res)=>{
        if (res.code === 0) {
          getList()
          Message({
            type: 'success',
            message: '删除成功!'
          });
        }
      })
    })
  }

  function openInfo (row, type='view') {
    const query = {
      type,
    }
    if (type !== 'add') {
      query.id = row.id
    }
    router.push({
      path: '/lease/info',
      query
    })
  }

  function handleSizeChange(e) {
    state.pageSize = e
    getList()
  }

  function handleCurrentChange(e) {
    state.currentPage = e
    getList()
  }

  function getList() {
    state.loading = true;
    axios.post(api.lease.list, {
      type: 2, 
      currentPage: state.currentPage,
      pageSize: state.pageSize
    }).then((res)=>{
      if (res.code === 0) {
        state.tableData = res?.data?.records ?? []
        state.total = res?.data?.total ? +res?.data?.total : 0
      }
    }).finally(()=>{
      state.loading = false
    })
  }

  onMounted(()=>{
    getList()
  })

</script>

<style lang="scss" scoped>

</style>